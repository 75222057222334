import { roistat_getCookie } from '@front/shared/ds';
import { Form, FormSubmission } from '@shared/master-types';
import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { usePostFormSubmissionMutation } from './postFormSubmission.query';
import { UTM_COOKIE_PARAMS } from '../../consts/UTM_COOKIE_PARAMS';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFormSubmission = (args: { form?: Form | string }) => {
  const { form } = args;

  if (!form || typeof form === 'string') {
    throw new Error(
      'Invalid form value provided, check if form exists or has proper access settings.',
    );
  }

  const [submitForm] = usePostFormSubmissionMutation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState,
    setError,
    trigger,
    getFieldState,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
  });

  const handleFormSubmit = useCallback(
    async (
      formData: Record<
        NonNullable<FormSubmission['submissionData']>[number]['field'],
        NonNullable<FormSubmission['submissionData']>[number]['value']
      >,
    ): Promise<void> => {
      try {
        const fields = form.fields || [];
        const submissionData: FormSubmission['submissionData'] = fields.map(
          f => {
            const data = {
              field: f.name,
              value: String(formData[f.name]),
            };

            if (f.preset === 'language' && !!f.options?.length) {
              data.value = f.options[Number(formData[f.name])].value;
            }

            return data;
          },
        );

        submissionData.push({
          field: 'roistat-promo-code',
          value: roistat_getCookie('roistat_visit') || '',
        });

        UTM_COOKIE_PARAMS.forEach(utmParam => {
          submissionData.push({
            field: utmParam.field,
            value: Cookies.get(utmParam.cookieName) || '',
          });
        });

        await submitForm({
          form: form.id,
          submissionData,
        });

        reset();
      } catch (e) {
        console.error(e);
      }
    },
    [form, reset, submitForm],
  );

  return {
    formState,
    control,
    register,
    setError,
    trigger,
    getFieldState,
    clearErrors,
    handleSubmit: handleSubmit(handleFormSubmit),
  };
};
