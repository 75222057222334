import { BaseApp, reportWebVitals, TBaseAppProps } from '@front/master/BaseApp';
import React from 'react';

import '../styles/globals.css';

const App: React.FC<TBaseAppProps> = props => {
  return <BaseApp {...props} />;
};

export { reportWebVitals };

export default App;
